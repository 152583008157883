/* eslint-disable @typescript-eslint/naming-convention */
export enum EMainPageEvents {
  BATTLE_BANNER_CLICKED = 'Main - Battle Banner Clicked',
  FREE_CASES_BANNER_CLICKED = 'Main - Free Cases Banner Clicked',
  FREE_CASES_CLICKED = 'Main - Free Cases Clicked',
  FREE_CASES_TAB_CLICKED = 'Main - Free Cases Tab Clicked',
  GIVEAWAY_TAB_CLICKED = 'Event - Giveaway Tab Clicked',
  INFO_POPUP_CLICKED = 'Event - Info Popup Clicked',
  NEW_CASES_TAB_CLICKED = 'Event - New Cases Tab Clicked',
  REWARD_CLAIMED = 'Event - Reward Claimed',
  SECRET_SHOP_OPENED = 'Main - Secret Shop Opened',
  SMALL_EVENT_CLICKED = 'Main - Small Event Clicked',
  TASK_COMPLETED = 'Event - Task Completed',
  TOP_PLAYERS_TAB_CLICKED = 'Event - Top Players Tab Clicked',
}

export interface IMainPageBattleBannerClickedEvent {
  'Button Place': string;
}

export interface IMainPageFreeCasesBannerClickedEvent {
  'Button Place': string;
  'Button Type': string;
}

export interface IMainPageRewardClaimedEvent {
  'Event Level': number;
  'Reward Place': string;
}

export interface IMainPageSecretShopOpenedEvent {
  'Button Place': string;
}

export interface IMainPageTaskCompletedEvent {
  ID: number;
}
