import type {
  IBattlepassApiLevel,
  IBattlepassApiLevelCase,
  IBattlepassApiLevelTaskProgress,
  IBattlepassApiLevelTaskReward,
  IBattlepassApiResponse,
} from './api.types';
import {
  EBattlepassLevelState,
  EBattlepassLevelType,
  type IBattlepassProgress,
  type IBattlepassIcons,
  type IBattlepassLevel,
  type IBattlepassTask,
  type IBattlepassTaskReward,
  type IBattlepassCase,
} from './client.types';
import type { TBackgroundColor, TPossibleNull } from '~/types/Shared.types';

export interface IBattlepassTransform {
  currentCoins: number;
  icons: IBattlepassIcons;
  levels: IBattlepassLevel[];
  progress: TBackgroundColor;
  tasks: IBattlepassTask[];
}

export const transform = (apiData: IBattlepassApiResponse): IBattlepassTransform => {
  const levels: IBattlepassLevel[] = [];
  const tasks: IBattlepassTask[] = [];

  let previousCost = 0;

  for (const level of apiData.levels) {
    const transformedLevel: IBattlepassLevel = {
      cost: level.cost - previousCost,
      costDiff: previousCost,
      level: level.level,
      state: getLevelState(level),
      type: getLevelType(level.level),
    };

    previousCost = level.cost;

    if (level.balanceData) {
      transformedLevel.balanceData = {
        coins: level.balanceData.coins ?? undefined,
        money: level.balanceData.money ?? undefined,
      };
    }

    if (level.caseData?.length === 1) {
      transformedLevel.caseData = getCaseObject(level.caseData[0]);
    }

    levels.push(transformedLevel);

    for (const task of level.tasks) {
      const progressData = getTaskProgress(task.progressData);
      if (progressData && progressData.current === progressData.end) continue;

      let description = task.description;
      if (task.task?.options.ids && task.task?.options.names) {
        description = getTaskTemplate(description, task.task.options.names, task.task.type);
      }

      tasks.push({
        description,
        id: task.id,
        level: level.level,
        progressData,
        reward: getTaskReward(task.reward),
        taskLink: getTaskLink(task.task?.type ?? ''),
      });
    }
  }

  const progress = {
    colorStops: apiData.challengeProgressGradient ?? [],
    toDirection: '90deg',
  };

  const icons = apiData.challengeIcons;
  icons.coin = getFullIconUrl(icons.coin);
  icons.rare = getFullIconUrl(icons.rare);
  icons.ultra = getFullIconUrl(icons.ultra);
  icons.case = getFullIconUrl(icons.case);

  return {
    currentCoins: apiData.currentCoins ?? 0,
    icons: icons as IBattlepassIcons,
    levels,
    progress,
    tasks,
  };
};

const getTaskTemplate = (template: string, names: string[], type: string) => {
  const types = {
    case: '%case_name%',
    offer: '%offer_name%',
  };

  const uniqueNames = [...new Set(names)];
  return template.replaceAll(types[type as keyof typeof types], uniqueNames.join(' / '));
};

const getCaseObject = (apiCase: IBattlepassApiLevelCase): IBattlepassCase => {
  return {
    amount: apiCase.amount,
    img: apiCase.img,
    link: `${useLinks().OPEN_CASE}${apiCase.url}`,
    name: apiCase.name,
  };
};

const getFullIconUrl = (icon: TPossibleNull<string>) => {
  if (!icon) return '';
  if (icon.includes('https')) return icon;
  return `${useLinks().domen}${icon}`;
};

/* Метод получения состояния карточки по данным с API */
const getLevelState = (level: IBattlepassApiLevel) => {
  if (level.current) return EBattlepassLevelState.CURRENT;
  if (!level.active) return EBattlepassLevelState.LOCKED;
  if (!level.done) return EBattlepassLevelState.DONE;
  return EBattlepassLevelState.RECEIVED;
};

/* Метод получения типа карточки по уровню */
const getLevelType = (level: number) => {
  if (level === 50) return EBattlepassLevelType.ULTRA;

  const rares = [15, 30, 40];
  if (rares.includes(level)) return EBattlepassLevelType.RARE;

  return EBattlepassLevelType.CLASSIC;
};

const getTaskProgress = (progress: TPossibleNull<IBattlepassApiLevelTaskProgress>): IBattlepassProgress | undefined => {
  if (!progress) return undefined;
  if (!progress.end || progress.end === 1) return undefined;

  return {
    current: progress.current ?? 0,
    end: progress.end,
  };
};

const getTaskReward = (reward: IBattlepassApiLevelTaskReward): IBattlepassTaskReward => {
  if (reward.money) return { money: reward.money };
  if (reward.cases?.length === 1) return { case: getCaseObject(reward.cases[0]) };
  return { coins: reward.coins ?? 0 };
};

const getTaskLink = (type: string) => {
  const {
    $projectConfig: {
      redirects: { newCases },
    },
  } = useNuxtApp();

  const taskLinks = {
    battle: useLinks().BATTLES,
    case: newCases.path,
    contracts: useLinks().CONTRACT,
    money: useLinks().PROFILE,
    offer: useLinks().SECRETSHOP,
    subscribe: useLinks().BONUS_PAGE,
    upgrade: useLinks().UPGRADES,
  };
  if (Object.keys(taskLinks).includes(type)) return taskLinks[type as keyof typeof taskLinks];
  return `${useLinks().domen}/`;
};
